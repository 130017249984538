import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'; 
import { checkIsTabbar, checkPageStack } from '@/util/util'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '/',
    redirect: '/main/index'
  },
  {
    path: '/user/login',
    name: '/user/login',
    component: () => import('../views/user/Login.vue')
  },
  {
    path: '/main',
    name: '/main',
    component: () => import('../views/main/Home.vue'),
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('../views/main/Index.vue'),
      },
      {
        path: 'detail',
        name: 'detail',
        component: () => import('../views/main/KbDetail.vue'),
      },
      {
        path: 'awardList',
        name: 'awardList',
        component: () => import('../views/main/AwardList.vue'),
      },
      {
        path: 'signInList',
        name: 'signInList',
        component: () => import('../views/main/SignInList.vue'),
      },

      {
        path: 'message',
        name: 'message',
        component: () => import('../views/main/Message.vue'),
      },
      {
        path: 'platform',
        name: 'platform',
        component: () => import('../views/main/Platform.vue'),
      },
      {
        path: 'add',
        name: 'add',
        component: () => import('../views/main/AddContent.vue')
      },
    ]
  }, 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0}
  },
})
// router/index.js
router.afterEach((to, from) => {
  let isTabbarData = checkIsTabbar(to);
  if (isTabbarData.isTabbar) {
    store.commit('SET_TABBAR_CURRENT', isTabbarData.tabbarName);
  }

  checkPageStack(to, from);

  // 设置标题
  document.title = to.query.title || to.meta.title || '天眼行动'
})
export default router
