 export function checkIsTabbar(to) {
// 定义你的逻辑
  return {
    isTabbar: true, // 示例返回值
    tabbarName: 'home' // 示例返回值
  };
}  

export function checkPageStack(to, from) {
// 定义你的逻辑
  console.log('Checking page stack:', to, from);
}
